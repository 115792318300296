export const injectError = (error: string, form: HTMLFormElement) => {
  var tagError = document.getElementById('loginErrorMessage')

  if (!!tagError)
    // eslint-disable-next-line immutable/no-mutation
    return (tagError.innerText = error)

  var elError = `<p style="white-space: pre-line" id="loginErrorMessage" class="errorMessage-customizable">${error}</p>`
  return form.insertAdjacentHTML('afterbegin', elError)
}

export const removeError = (form: HTMLFormElement) => {

  var tagError = document.getElementById('loginErrorMessage');

  if (!!tagError) {
    tagError.remove();
  }

  var submitButton = form.querySelector('button[name="signInSubmitButton"]');

  if (submitButton) {
    submitButton.removeAttribute('disabled');
  }

}

export const processForm = (e: any, form: HTMLFormElement) => {
  var reCaptchaField = document.getElementById(
    'g-recaptcha-response',
  ) as HTMLTextAreaElement

  if (!!reCaptchaField && !reCaptchaField.value) {
    injectError('Captcha validation is required.', form)
    return e.preventDefault()
  } else {
    removeError(form)
    return e.preventDefault()
  }
}
